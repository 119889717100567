export default [
  {
    group: 'Главное',
    items: [
      { text: 'Таблица возвратов', icon: 'mdi-clipboard-arrow-left', pageName: 'returns' },
      { text: 'Баланс поставщика', icon: 'mdi-bank', pageName: 'suppliers-balance' },
      { text: 'Аналитика', icon: 'mdi-finance', pageName: 'analytics', isDisabled: true }
    ]
  },
  {
    group: 'Справочники',
    items: [
      { text: 'Точки возвратов', icon: 'mdi-map-marker', pageName: 'return-points' }
    ]
  }
]
